'use client'

import type { MenuItemLinkProps } from '../MenuItemLink'
import React, { startTransition, useState } from 'react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import PostAddIcon from '@mui/icons-material/PostAdd'
import SettingsIcon from '@mui/icons-material/Settings'
import SearchIcon from '@mui/icons-material/Search'
import SportsIcon from '@mui/icons-material/Sports'
import StadiumIcon from '@mui/icons-material/Stadium'
import StorageIcon from '@mui/icons-material/Storage'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk'
import MuiAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Menu from '@mui/material/Menu'
import Badge from '@mui/material/Badge'
import IconButton from '@mui/material/IconButton'
import { usePathname, useSearchParams, useParams } from 'next/navigation'
import { useSession } from 'next-auth/react'
import { Divider } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/Error'
import { useQueryClient } from '@tanstack/react-query'
import PublicIcon from '@mui/icons-material/Public'
import TimerIcon from '@mui/icons-material/Timer'
import { useRouter } from 'next-nprogress-bar'
import SearchInput from '../search/SearchInput'
import MobileSearchInput from '../search/MobileSearchInput'
import BinocularIcon from '../icon/BinocularIcon'
import RiseLogo from '../icon/RiseLogo'
import RiseLogoWithText from '../icon/RiseLogoWithText'
import MenuItemLink from '../MenuItemLink'
import { useQueryAllPosts, useWatchVariable } from '../../lib/hooks'
import { LogoutMenuItem } from '../wrappers/Auth'
import ElevateOnScroll from '../ElevateOnScroll'
import NotificationsList from '../notifications/NotificationsList'
import Link from '../Link'
import { useIsBlocked } from '../NavigationBlock'
import { unsavedChangesMessage } from '@/lib/utils/form'

export const appBarHeight = 48

const menuItemLinkStyle = { color: 'grey', fontSize: 'inherit' } as const
const profileLabel = 'account of current user' as const

const profileMenuItems = (
    params: Record<string, string | string[]> | null,
    roles?: Auth.UserRoles
): MenuItemLinkProps[] => [
    {
        href: roles?.featurePermissions['site-management']
            ? '/site-management/user-groups'
            : '/site-management/performance',
        label: 'Site Management',
        authorize: !!roles?.featurePermissions['site-management'] || !!roles?.featurePermissions['site-performance'],
        icon: <SettingsIcon style={menuItemLinkStyle} />,
    },
    {
        href: roles?.contentPermissions['player-management']
            ? '/data-management/import-players'
            : '/data-management/scouting-events',
        label: 'Data Management',
        authorize: !!roles?.featurePermissions['data-management'],
        icon: <StorageIcon style={menuItemLinkStyle} />,
    },
    {
        href: 'https://forms.office.com/Pages/ResponsePage.aspx?id=R-jB8tJboku15LUppkXzGj_qrSCJnFtEqOGCvnN-XZlUQUFFVEg4MkpXNTVPSUhFVjJBU0xSWldJUC4u',
        label: 'Report Issue',
        authorize: true,
        icon: <ErrorOutlineIcon style={menuItemLinkStyle} />,
        newTab: true,
    },
]

const postLabel = 'add post'
const postMenuItems = (
    params: Record<string, string | string[]> | null,
    roles?: Auth.UserRoles
): MenuItemLinkProps[] => {
    const hasScoutingReportPermissions = !!roles?.featurePermissions['create-scouting-reports']
    const hasIntelReportPermissions =
        !!roles?.contentPermissions['pro-intel-on-court'] ||
        !!roles?.contentPermissions['pro-intel-off-court'] ||
        !!roles?.contentPermissions['pro-intel-medical-physical'] ||
        !!roles?.contentPermissions['pro-intel-strategy'] ||
        !!roles?.contentPermissions['amateur-intel-on-court'] ||
        !!roles?.contentPermissions['amateur-intel-off-court'] ||
        !!roles?.contentPermissions['amateur-intel-medical-physical'] ||
        !!roles?.contentPermissions['amateur-intel-strategy']
    const hasPlayerDevelopmentReportPermissions =
        !!roles?.contentPermissions['knicks-player-development-coaching'] ||
        !!roles?.contentPermissions['knicks-player-development-physical'] ||
        !!roles?.contentPermissions['westchester-player-development-coaching'] ||
        !!roles?.contentPermissions['westchester-player-development-physical']
    const hasCommunityReportPermissions =
        !!roles?.contentPermissions['knicks-community'] || !!roles?.contentPermissions['westchester-community']
    const hasGameReportPermissions = !!roles?.featurePermissions['create-game-reports']
    const hasWorkoutPermissions = !!roles?.featurePermissions['create-workout-reports']

    return [
        {
            href: {
                pathname: '/posts/create/intel',
                query: params?.playerid
                    ? { playerid: params.playerid }
                    : params?.teamid
                    ? { teamid: params.teamid }
                    : {},
            },
            label: 'Intel',
            authorize: hasIntelReportPermissions,
            icon: <PhoneInTalkIcon style={menuItemLinkStyle} />,
        },
        {
            href: {
                pathname: '/posts/create/scouting',
                query: params?.playerid ? { playerid: params.playerid } : {},
            },
            label: 'Scouting',
            authorize: hasScoutingReportPermissions,
            icon: <BinocularIcon style={menuItemLinkStyle} />,
        },
        {
            href: {
                pathname: '/posts/create/player-development',
            },
            label: 'Player Dev',
            authorize: hasPlayerDevelopmentReportPermissions,
            icon: <SportsIcon style={menuItemLinkStyle} />,
        },
        {
            href: {
                pathname: '/posts/create/community',
            },
            label: 'Community',
            authorize: hasCommunityReportPermissions,
            icon: <PublicIcon style={menuItemLinkStyle} />,
        },
        {
            href: {
                pathname: '/posts/create/game',
            },
            label: 'Game',
            authorize: hasGameReportPermissions,
            icon: <StadiumIcon style={menuItemLinkStyle} />,
        },
        {
            href: {
                pathname: '/posts/create/workout',
            },
            label: 'Workout',
            authorize: hasWorkoutPermissions,
            icon: <TimerIcon style={menuItemLinkStyle} />,
        },
    ]
}

const AppBar = (): JSX.Element | null => {
    const [searchOpen, setSearchOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const pathname = usePathname()
    const searchParams = useSearchParams()
    const router = useRouter()
    const params = useParams()
    const { data: session } = useSession()
    const canViewPostFeed = !!session?.roles.featurePermissions['post-feed']
    // keep these parameters (perPage) consistent with `drafts/index.tsx` to use react-query cache between components
    const { data: posts } = useQueryAllPosts(
        undefined,
        { status: ['DRAFT'], perPage: 50 },
        { enabled: canViewPostFeed && !searchParams?.get('printOnly') }
    )
    const queryClient = useQueryClient()
    const isBlocked = useIsBlocked()

    useWatchVariable(() => setSearchOpen(false), pathname)

    /* DO NOT SHOW HEADER ON /AUTH ROUTES */
    if (pathname?.includes('/auth')) return null

    const elevateRiseHeader = pathname === '/' || pathname === '/posts/create/intel'

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleSearch = () => {
        setSearchOpen(!searchOpen)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleLogoClick = (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.MouseEvent<HTMLSpanElement, MouseEvent>
    ) => {
        void queryClient.invalidateQueries(['posts'])
        void queryClient.invalidateQueries(['notifications'])

        // prevent default navigation for dirty forms
        e.preventDefault()
        // eslint-disable-next-line no-alert
        if (isBlocked && !window.confirm(unsavedChangesMessage)) {
            return
        }
        startTransition(() => {
            router.push('/')
        })
    }

    let getMenuItems:
        | ((params: Record<string, string | string[]> | null, roles?: Auth.UserRoles) => MenuItemLinkProps[])
        | undefined
    const menuType = anchorEl?.getAttribute('aria-label')
    if (menuType === postLabel)
        getMenuItems = session?.roles.featurePermissions['post-feed'] ? postMenuItems : undefined
    else if (menuType === profileLabel) getMenuItems = profileMenuItems

    return (
        <ElevateOnScroll elevationHeight={elevateRiseHeader ? 3 : 0}>
            <MuiAppBar
                sx={{
                    displayPrint: 'none',
                    position: 'sticky',
                    backgroundColor: 'common.white',
                    zIndex: (theme) => theme.zIndex.appBar + 1,
                    borderBottom: 1,
                    borderColor: 'divider',
                }}
            >
                <Toolbar
                    sx={{ justifyContent: 'space-between', height: appBarHeight, minHeight: { xs: appBarHeight } }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', flex: 1, alignItems: 'center' }}>
                        <Link href="/" onClick={handleLogoClick} sx={{ display: { xs: 'none', sm: 'flex' } }}>
                            <RiseLogoWithText fontSize={40} />
                        </Link>
                        <Link href="/" onClick={handleLogoClick} sx={{ display: { xs: 'flex', sm: 'none' } }}>
                            <RiseLogo fontSize={40} />
                        </Link>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                            }}
                        >
                            <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                                <SearchInput />
                            </Box>
                            {searchOpen && <MobileSearchInput searchOpen={searchOpen} handleSearch={handleSearch} />}
                            {!searchOpen && (
                                <IconButton
                                    size="large"
                                    edge="end"
                                    aria-label="search"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleSearch}
                                    sx={{ display: { sm: 'none' } }}
                                >
                                    <SearchIcon />
                                </IconButton>
                            )}
                            {canViewPostFeed && (
                                <IconButton
                                    size="large"
                                    aria-label={postLabel}
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    edge="end"
                                    onClick={handleMenu}
                                >
                                    <PostAddIcon />
                                </IconButton>
                            )}

                            <NotificationsList />
                            <IconButton
                                size="large"
                                edge="end"
                                aria-label={profileLabel}
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                            >
                                <AccountCircleIcon />
                            </IconButton>
                        </Box>
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorEl)}
                            onClick={handleClose}
                            onClose={handleClose}
                        >
                            {getMenuItems?.(params, session?.roles).map(({ href, label, authorize, icon, newTab }) => (
                                <MenuItemLink
                                    key={label}
                                    href={href}
                                    label={label}
                                    authorize={authorize}
                                    icon={icon}
                                    newTab={newTab}
                                />
                            ))}
                            {menuType === profileLabel && <LogoutMenuItem />}
                            {menuType === postLabel && (
                                <div>
                                    <Divider />
                                    <MenuItemLink
                                        key="/posts/drafts"
                                        href="/posts/drafts"
                                        label="My Drafts"
                                        authorize={canViewPostFeed}
                                        icon={
                                            <Badge
                                                badgeContent={posts?.pages[0]?.results.length || '0'}
                                                sx={{
                                                    postion: 'relative',
                                                    left: 8,
                                                    marginRight: 2,
                                                    '& .MuiBadge-badge': {
                                                        color: 'white',
                                                        backgroundColor: 'grey.500',
                                                    },
                                                }}
                                            />
                                        }
                                    />
                                </div>
                            )}
                        </Menu>
                    </Box>
                </Toolbar>
            </MuiAppBar>
        </ElevateOnScroll>
    )
}

export default AppBar
